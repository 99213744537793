.invalid-feedback {
  display: block;
}

input.is-loading {
  background-image: url("./images/rotation.gif");
  background-size: 15px;
  background-position: right center;
  background-repeat: no-repeat;
  background-origin: content-box;
}

.modal {
  display: block;
  overflow: auto;
}

.modal.fade .modal-dialog {
  transition-duration: .15s;
}

.box-narrow {
  max-width: 600px;
  min-width: 300px;
}

#root {
  display: flex;
}

.app {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
  display: flex;
}

.table td,
.table th {
  vertical-align: middle;
}

table.table tbody tr.group-next-row td {
  border-bottom: none;
  padding-bottom: 2px;
}
